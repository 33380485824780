import { React, useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import ProjectList from './components/ProjectList';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CalculateIcon from '@mui/icons-material/Calculate';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PropertyList from './components/PropertyList';
import WorkGroupTypeList from './components/WorkGroupTypeList'
import Layout from '../common/components/Layout';

export default function MainPage() {
    const [selectedProjectID, setSelectedProjectID] = useState();
    const [selectedTakeoffTemplateID, setSelectedTakeoffTemplateID] = useState();//
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedPropertyID, setSelectedPropertyID] = useState();

    const handleProjectSelect = useCallback((project) => {
        setSelectedProjectID(project.id);
        setSelectedTakeoffTemplateID(project.takeoffTemplateID);
    }, [])

    const handlePropertySelect = useCallback(async (property) => {
        setSelectedPropertyID(property.id);
    }, [])

    const handleResetProjectSelect = () => {
        setSelectedProjectID(undefined);
        setSelectedTakeoffTemplateID(undefined);
        setSelectedPropertyID(undefined);
    }

    return (
        <Layout>
            <Grid container spacing={1} style={{ height: '100%' }}>
                <Grid item xs={4}>
                    <ProjectList
                        handleProjectSelect={handleProjectSelect}
                        selectedProjectID={selectedProjectID}
                        handleResetProjectSelect={handleResetProjectSelect}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Tabs
                        value={selectedTabIndex}
                        onChange={(event, newValue) => setSelectedTabIndex(newValue)}
                        variant="fullWidth"
                        style={{ height: '10vh' }}
                    >
                        <Tab icon={<CalculateIcon />} label="Calculate" />
                        <Tab icon={<FeaturedPlayListIcon />} label="Properties" />
                        <Tab icon={<AssessmentIcon />} label="Reports" />
                    </Tabs>
                    <Box style={{ height: '90vh' }}>
                        <WorkGroupTypeList
                            takeoffTemplateID={selectedTakeoffTemplateID}
                            projectID={selectedProjectID}
                            hidden={selectedTabIndex !== 0}
                        />
                        <PropertyList
                            handlePropertySelect={handlePropertySelect}
                            setSelectedPropertyID={setSelectedPropertyID}
                            projectID={selectedProjectID}
                            selectedPropertyID={selectedPropertyID}
                            hidden={selectedTabIndex !== 1}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Layout>
    );
}