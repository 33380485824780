import React, { useEffect, useState } from 'react';
import PricingSection from './PricingSection';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export default function PagePricingSectionList({ workOrderID, workTypeID, rowListRenderID, isReadOnly, renewPricingGroupsRenderID }) {
    const [calculationAreaTypeIDs, setCalculationAreaTypeIDs] = useState();
    const [isLoading, setIsLoading] = useState();
    const approxAPIClient = ApproxAPIClient();

    useEffect(() => {
        try {
            setIsLoading(true);
            approxAPIClient.fetchCalculationAreaTypeIDsByWorkTypeID(workTypeID).then((response) => {
                if (response.isSuccessful === true) {
                    setCalculationAreaTypeIDs(response.data);
                } else {
                    alert(response.error);
                }

            }).then(() => {
                setIsLoading(false);
            });
        } catch (error) {
            console.error('Error fetching property data:', error);
        }
    }, [approxAPIClient, workTypeID])

    return (
        <Box sx={{ padding: 0, overflowX: 'hidden', overflowY: 'auto', height: '100%' }}>
            {
                isLoading || !calculationAreaTypeIDs ? <Box sx={{ width: '100%', padding: '5%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </Box> : calculationAreaTypeIDs.map((calculationAreaTypeID) => (
                    <PricingSection
                        key={`PricingSection#${calculationAreaTypeID}`}
                        isReadOnly={isReadOnly}
                        workOrderID={workOrderID}
                        rowListRenderID={rowListRenderID}
                        calculationAreaTypeID={calculationAreaTypeID}
                        renewPricingGroupsRenderID={renewPricingGroupsRenderID}
                    />
                ))
            }
        </Box>
    )
}