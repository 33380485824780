import React, { useState, useEffect, useCallback } from 'react';
import InputField from './InputField';
import InputFieldDataType from '../../../constants/InputFieldDataType';
import RowResult from './RowResult';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function Row({
  row,
  rowTypes,
  rowIndex,
  activeRowIndex,
  deleteRow,
  workOrderID,
  isReadOnly,
  upsertRow,
  setActiveRowIndex,
  workOrderCalculationAreaGroups,
  workOrderCalculationAreaGroupSubmit }) {

  const [selectedRowType, setSelectedRowType] = useState(() => {
    return rowTypes.find(x => x.id === row.rowTypeID)
  });
  const [inputFieldIndexInputFieldRefDict, setInputFieldIndexInputFieldRefDict] = useState();
  const [activeInputField, setActiveInputField] = useState();
  const [inputFieldData, setInputFieldData] = useState([]);
  const [isCardFront, setIsCardFront] = useState(true);
  const [calculationAreaAssignees, setCalculationAreaAssignees] = useState();

  useEffect(() => {
    if (!row) {
      return;
    }

    const data = row.calculationAreaResults.map((x) => ({
      calculationAreaTypeID: x.calculationAreaTypeID,
      workOrderCalculationAreaGroupID: x.workOrderCalculationAreaGroupID
    }));

    setCalculationAreaAssignees(data);
  }, [row])

  const [selectedCalculationAreaTypeID, setSelectedCalculationAreaTypeID] = useState();

  const options = rowTypes.sort((x, y) => x.sorting - y.sorting).map(rowType =>
    (<MenuItem value={rowType.id} key={`${row.id}#${rowType.id}`}>{rowType.name}</MenuItem>));

  const [rowTypeDDValue, setRowTypeDDValue] = useState(selectedRowType?.id)

  const handleWorkOrderCalculationAreaGroupSelect = (workOrderCalculationAreaGroupID) => {
    const others = calculationAreaAssignees.filter(x => x.calculationAreaTypeID !== selectedCalculationAreaTypeID);
    let thisC = calculationAreaAssignees.find(x => x.calculationAreaTypeID === selectedCalculationAreaTypeID);
    thisC.workOrderCalculationAreaGroupID = workOrderCalculationAreaGroupID;
    setCalculationAreaAssignees([...others, thisC]);
  }

  const handleWorkOrderCalculationAreaGroupDiscard = () => {
    const data = row.calculationAreaResults.map((x) => ({
      calculationAreaTypeID: x.calculationAreaTypeID,
      workOrderCalculationAreaGroupID: x.workOrderCalculationAreaGroupID
    }));

    setCalculationAreaAssignees([...data]);
    setSelectedCalculationAreaTypeID(undefined);
  }

  const handleWorkOrderCalculationAreaGroupSubmit = () => {
    workOrderCalculationAreaGroupSubmit(row.id, calculationAreaAssignees)
  }

  useEffect(() => {
    function prepareAndSetInputFieldIndexInputFieldRefDict(rowType, isNew) {
      const result = [];

      if (isNew) {
        const selectRef = React.createRef();
        result.push({
          key: { rowIndex: rowIndex, inputFieldIndex: 0 },
          value: selectRef,
        });
      }

      if (!rowType) {
        setInputFieldIndexInputFieldRefDict(result);
        return;
      }

      result.push(
        ...(rowType.inputFieldTypes.map(x => ({
          key: { rowIndex: rowIndex, inputFieldIndex: x.sorting },
          value: React.createRef(),
        })))
      );

      const exisiting = isNew ? [] : inputFieldIndexInputFieldRefDict ? inputFieldIndexInputFieldRefDict : [];
      setInputFieldIndexInputFieldRefDict([...result, ...exisiting]);
    };

    if (!inputFieldIndexInputFieldRefDict) {
      prepareAndSetInputFieldIndexInputFieldRefDict(selectedRowType, true);
    }

    if (inputFieldIndexInputFieldRefDict && inputFieldIndexInputFieldRefDict.length === 1) {
      prepareAndSetInputFieldIndexInputFieldRefDict(selectedRowType, false);
    }

  }, [selectedRowType, inputFieldIndexInputFieldRefDict, row.id, rowIndex]);

  useEffect(() => {
    function setFirstInputFieldActive() {
      const isRowActive = activeRowIndex === rowIndex;

      if (inputFieldIndexInputFieldRefDict && isRowActive && !activeInputField) {
        const firstInputFieldRef = inputFieldIndexInputFieldRefDict.find(
          x => x.key.rowIndex === activeRowIndex && x.key.inputFieldIndex === 0
        )?.value;
        if (firstInputFieldRef?.current) {
          firstInputFieldRef.current.focus();
          setActiveInputField(firstInputFieldRef);
        }
      }

      if (activeInputField && activeInputField.current) {
        activeInputField.current.focus();
      }
    }

    setFirstInputFieldActive();
  }, [inputFieldIndexInputFieldRefDict, activeRowIndex, rowIndex, activeInputField]);

  const handleSelectedRowType = useCallback((selected) => {
    setSelectedRowType(selected);
    setActiveInputField(undefined);
    setInputFieldIndexInputFieldRefDict(undefined);
    setInputFieldData([]);
  }, []);

  useEffect(() => {
    if (!rowTypeDDValue || rowTypeDDValue === selectedRowType?.id) {
      return;
    }

    let selected = rowTypes.find(x => x.id === rowTypeDDValue);
    handleSelectedRowType(selected);
  }, [rowTypeDDValue, rowTypes, selectedRowType?.id, handleSelectedRowType])

  const setNextInputFieldActive = async (e, currentInputFieldIndex) => {
    if (!activeInputField) {
      return;
    }

    if (e.code === 'Escape' && !selectedRowType) {
      await deleteRow(currentInputFieldIndex);
      return;
    }

    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      let nextInputFieldIndex = -1;

      if (!selectedRowType) {
        return;
      }

      for (let inputFieldType of selectedRowType.inputFieldTypes) {
        if (inputFieldType.sorting > currentInputFieldIndex) {
          nextInputFieldIndex = inputFieldType.sorting;
          break;
        }
      }

      e.stopPropagation();
      e.preventDefault();

      if (nextInputFieldIndex === -1 && (!e.target.id.includes('select') || (e.target.id.includes('select') && e.target.value !== ''))) {
        const upsertRowinputFieldDataInput = inputFieldData.map(x => ({
          inputFieldTypeID: x.InputFieldTypeID,
          textValue: x.TextValue,
          numericValue: x.NumericValue,
          dropdownValue: x.DropdownValue
        }));

        let upsertRowInput = {
          id: row?.id ?? null,
          rowTypeID: selectedRowType.id,
          workOrderID: workOrderID,
          fields: upsertRowinputFieldDataInput
        };

        upsertRow(e, upsertRowInput);
        return;
      }

      let ref = inputFieldIndexInputFieldRefDict.find(
        x =>
          x.key.rowIndex === activeRowIndex &&
          x.key.inputFieldIndex === nextInputFieldIndex
      )?.value;

      if (ref && ref.current) {
        ref.current.focus();
        setActiveInputField(ref);
      }
    }
  }

  const fillInputFieldData = useCallback((inputTextValue, inputNumericValue, inputFieldType) => {
    const exisitingFieldData = inputFieldData.find(x => x.InputFieldTypeID === inputFieldType.id);
    const thisFieldData = {
      InputFieldTypeID: inputFieldType.id,
      TextValue: inputTextValue,
      NumericValue: inputNumericValue,
      DataType: inputFieldType.dataType
    };

    if (!exisitingFieldData
      || (exisitingFieldData.TextValue !== thisFieldData.TextValue && inputFieldType.dataType === InputFieldDataType.Text)
      || (exisitingFieldData.NumericValue !== thisFieldData.NumericValue && inputFieldType.dataType === InputFieldDataType.Numeric)) {
      const otherFieldData = inputFieldData.filter(x => x.InputFieldTypeID !== inputFieldType.id);
      setInputFieldData([...otherFieldData, thisFieldData]);
    }
  }, [inputFieldData, setInputFieldData]);

  const prepareInputFields = (inputFieldTypes, dataType, equals) => {
    if (!inputFieldTypes) {
      return;
    }

    return inputFieldTypes
      .filter(x => (equals ? x.dataType === dataType : x.dataType !== dataType))
      .map((inputFieldType) => (
        <InputField
          key={`InputField#${rowIndex}-${inputFieldType?.id}`}
          data={row?.inputFields
            ? row.inputFields.find(
              x => x.inputFieldTypeID === inputFieldType.id
            )
            : undefined}
          inputFieldType={inputFieldType}
          inputFieldReference={inputFieldIndexInputFieldRefDict?.find(
            x =>
              x.key.rowIndex === activeRowIndex &&
              x.key.inputFieldIndex === inputFieldType.sorting
          )?.value}
          selectedRowType={selectedRowType}
          rowIndex={rowIndex}
          fillInputFieldData={fillInputFieldData}
          setNextInputFieldActive={setNextInputFieldActive}
        />
      ));
  }

  const inputFieldTypes = selectedRowType?.inputFieldTypes;
  inputFieldTypes?.sort((x, y) => x.sorting - y.sorting);

  const textInputFields = inputFieldIndexInputFieldRefDict ? prepareInputFields(inputFieldTypes, InputFieldDataType.Text, true) : [];
  const otherInputFields = inputFieldIndexInputFieldRefDict ? prepareInputFields(inputFieldTypes, InputFieldDataType.Text, false) : [];

  return (
    !inputFieldIndexInputFieldRefDict ? <></> : <>
      <Card sx={{ opacity: isReadOnly ? 0.60 : 1, pointerEvents: isReadOnly ? 'none' : 'initial', height: '200px', marginTop: 1, width: '100%' }} onClick={() => setActiveRowIndex(rowIndex)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            width: '5%',
            padding: 1
          }}>
            {selectedRowType ? <IconButton onClick={() => { setIsCardFront(!isCardFront); handleWorkOrderCalculationAreaGroupDiscard(); }} color='info'>
              <SettingsIcon />
            </IconButton> : <></>}

            <IconButton onClick={deleteRow} color='error'>
              <DeleteIcon />
            </IconButton>
          </Box>
          <CardContent sx={{ height: '100%', width: '95%', display: !isCardFront ? 'none' : 'block' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '30%',
                paddingBottom: 1
              }}>

              <Select
                key={`RowType${row.id}-${0}`}
                fullWidth
                id={`RowType${row.id}-${0}`}
                style={{
                  backgroundColor: selectedRowType?.color ?? 'orange',
                  width: '30%',
                  height: '100%',
                  color: 'white',
                  fontWeight: 'bold'
                }}
                value={rowTypeDDValue ?? ''}
                inputRef={inputFieldIndexInputFieldRefDict.find(
                  x =>
                    x.key.rowIndex === activeRowIndex &&
                    x.key.inputFieldIndex === 0
                )?.value}
                onChange={(e) => setRowTypeDDValue(e.target.value)}
                onKeyDownCapture={(e) => setNextInputFieldActive(e, 0)}
              >
                {options}
              </Select>
              <Box
                sx={{
                  border: `1px solid ${selectedRowType?.color ?? 'orange'}`,
                  width: '70%',
                  height: '100%',
                  display: 'flex',
                  overflowX: 'auto',
                  overflowY: 'hidden'
                }}>
                {
                  row.calculationAreaResults.map(x => {
                    return <RowResult
                      key={`RowResult#${rowIndex}-${x.calculationAreaTypeID}`}
                      areaName={x.calculationAreaTypeName}
                      areaValue={Number(x.result).toFixed(x.calculationAreaTypePrecision)}
                      color={x.calculationAreaTypeColor}
                      calculationAreaGroupName={x.workOrderCalculationAreaGroupName}
                      calculationAreaGroupID={x.workOrderCalculationAreaGroupID}
                    />
                  })
                }
              </Box>
            </Box>
            <Box style={{ height: '70%', display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', width: '100%', overflow: 'hidden' }}>
              {(textInputFields?.length ?? 0) === 0 && (otherInputFields?.length ?? 0) === 0 ? <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box> : <>
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', padding: 1, width: '30%' }}>{textInputFields}</Box>
                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'start', flexWrap: 'wrap', padding: 1, width: '70%' }}>{otherInputFields}</Box>
              </>}
            </Box>
          </CardContent>
          <CardContent sx={{ height: '100%', width: '95%', display: isCardFront ? 'none' : 'block' }}>
            {
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: '100%',
                  padding: 0,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'left',
                    alignItems: 'center',
                    height: '30%',
                    width: '100%',
                    backgroundColor: selectedRowType?.color,
                    padding: 2,
                    borderRadius: 1
                  }}
                >
                  <Typography
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      verticalAlign: 'middle',
                      fontSize: '1rem'
                    }}
                  >
                    {selectedRowType?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 1
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      width: '90%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 1
                    }}
                  >
                    <ToggleButtonGroup
                      value={selectedCalculationAreaTypeID}
                      sx={{
                        height: '100%',
                        width: '100%',
                        marginBottom: 1
                      }}
                    >
                      {row.calculationAreaResults.sort((a, b) => (a.calculationAreaTypeSorting - b.calculationAreaTypeSorting)).map((x) => (
                        <ToggleButton key={x.calculationAreaTypeID}
                          value={x.calculationAreaTypeID}
                          onClick={() => setSelectedCalculationAreaTypeID(x.calculationAreaTypeID)}
                          sx={{
                            backgroundColor: x.calculationAreaTypeColor,
                            width: '20%',
                            height: '100&',
                            color: 'white',
                            fontSize: 10
                          }}>
                          {x.calculationAreaTypeName}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                      value={calculationAreaAssignees.find(x => x.calculationAreaTypeID === selectedCalculationAreaTypeID)?.workOrderCalculationAreaGroupID}
                      sx={{
                        height: '100%',
                        width: '100%',
                        justifyContent: 'center'
                      }}
                    >
                      {
                        selectedCalculationAreaTypeID ? workOrderCalculationAreaGroups
                          .filter(x => x.calculationAreaTypeID === selectedCalculationAreaTypeID)
                          .map((x) => (<ToggleButton
                            key={x.id}
                            value={x.id}
                            onClick={() => handleWorkOrderCalculationAreaGroupSelect(x.id)}
                          >
                            {x.name}
                          </ToggleButton>)) : []
                      }
                    </ToggleButtonGroup>
                  </Box>
                  <Box
                    sx={{
                      height: '100%',
                      width: '10%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: 1
                    }}
                  >
                    <IconButton onClick={handleWorkOrderCalculationAreaGroupDiscard}>
                      <ClearIcon color='error' />
                    </IconButton>

                    <IconButton onClick={handleWorkOrderCalculationAreaGroupSubmit}>
                      <CheckIcon color='success' />
                    </IconButton>

                  </Box>

                </Box>

              </Box>
            }
          </CardContent>
        </Box>

      </Card>
    </>
  );
}

export default Row;
