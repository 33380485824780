import React, { useState, useEffect, useCallback } from 'react';
import ApproxAPIClient from '../../../ApproxAPIClient'
import { useSearchParams } from 'react-router-dom'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelIcon from '@mui/icons-material/Label';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';

function WorkTypeList({ handleWorkTypeSelect, selectedWorkTypeID }) {
    const [searchParams] = useSearchParams();
    const workGroupTypeID = searchParams.get("workGroupTypeID");
    const [selectedPropertyTypeWorkTypes, setSelectedPropertyTypeWorkTypes] = useState();
    const approxAPIClient = ApproxAPIClient();
    const [isLoading, setIsLoading] = useState(false);

    const handleListItemClick = useCallback((e, node) => {
        handleWorkTypeSelect(node);
    }, [handleWorkTypeSelect]);

    useEffect(() => {
        if (!workGroupTypeID) {
            return;
        }

        setIsLoading(true);
        approxAPIClient.fetchWorkTypeNamesByWorkGroupTypeID(workGroupTypeID).then((response) => {
            if (response.isSuccessful === true) {
                setSelectedPropertyTypeWorkTypes(response.data);

                if (response.data?.length > 0) {
                    handleListItemClick(null, response.data[0]);
                }
            } else {
                alert(response.error);
            }

        }).then(() => {
            setIsLoading(false);
        });

    }, [workGroupTypeID, approxAPIClient, handleListItemClick])

    return (
        <>
            <Paper style={{ height: '100%' }}>
                <List
                    component="nav"
                    aria-label="work type list"
                    style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            <Stack spacing={1} direction="row">
                                <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                                    Work Types
                                </Typography>
                            </Stack>
                        </ListSubheader>
                    }
                >
                    {!isLoading && selectedPropertyTypeWorkTypes && selectedPropertyTypeWorkTypes.length > 0 ? selectedPropertyTypeWorkTypes.map(x =>
                        <ListItemButton
                            selected={x.id === selectedWorkTypeID}
                            onClick={(e) => handleListItemClick(e, x)}
                            key={x.id}
                        >
                            <ListItemIcon>
                                <LabelIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={x.name}
                                primaryTypographyProps={{
                                    fontSize: 15,
                                    fontWeight: 'medium',
                                    lineHeight: '50px',
                                    mb: '2px',
                                    color: 'black'
                                }}

                            />
                        </ListItemButton>
                    ) : !isLoading && selectedPropertyTypeWorkTypes && selectedPropertyTypeWorkTypes.length === 0 ?
                        <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="center">
                            There is no work type!
                        </Typography> : <Box sx={{ width: '100%', padding: '5%' }}>
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                        </Box>}
                </List>
            </Paper>
        </>

    );
}

export default WorkTypeList;