import { React, useState, useEffect } from 'react';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import WorkOrderState from '../../../constants/WorkOrderState'

export default function StatusBar({ workOrderID, rowListRenderID }) {

  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState();
  const approxAPIClient = ApproxAPIClient();

  const circleOneState = status?.state > WorkOrderState.None;
  const circleTwoState = status?.state > WorkOrderState.Created;
  const circleThreeState = status?.state > WorkOrderState.Started;

  useEffect(() => {
    setIsLoading(true);
    approxAPIClient.fetchWorkOrderStateByWorkOrderID(workOrderID).then((response) => {
      if (response.isSuccessful === true) {
        setStatus(response.data);
      } else {
        alert(response.error);
      }

    }).then(() => {
      setIsLoading(false);
    })
  }, [approxAPIClient, workOrderID, rowListRenderID])

  return (
    <Card sx={{ width: '100%' }}>
      {
        isLoading || !status ? <Box sx={{ width: '100%', padding: '5%' }}>
          <Skeleton animation="wave" />
        </Box> : <CardContent sx={{ display: 'flex', flexDirection: 'row', alignContent: 'space-between', justifyContent: 'center', padding: 3 }}>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
            <FiberManualRecordIcon style={{ fontSize: '30px', color: circleOneState ? 'green' : 'grey' }} />
            <FiberManualRecordIcon style={{ fontSize: '30px', color: circleTwoState ? 'green' : 'grey' }} />
            <FiberManualRecordIcon style={{ fontSize: '30px', color: circleThreeState ? 'green' : 'grey' }} />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '50%', height: '100%' }}>
            <Typography variant="h5" sx={{ fontSize: 8, textAlign: 'center' }} component="div">
              Assigned User
            </Typography>
            <Typography sx={{ fontSize: 10, textAlign: 'center', wordWrap: 'break-word' }} color="text.secondary" gutterBottom>
              {status.assignedUserName ?? 'None'}
            </Typography>
          </Box>
        </CardContent>
      }
    </Card>
  );
}