import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton'
import Papa from 'papaparse';


export default function AddPropertyDialog({ addPropertyIsOpen, setAddPropertyIsOpen, handleAddPropertySuccess, projectID, selectedProperty }) {
    const approxAPIClient = ApproxAPIClient();
    const [isLoading, setIsLoading] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [columns, setColumns] = useState(undefined);

    const handleInputChange = useCallback((value, column) => {
        setInputValues({
            ...inputValues,
            [column]: value,
        });

    }, [setInputValues, inputValues]);

    const handleAddPropertyClose = () => {
        setIsLoading(false);
        setAddPropertyIsOpen(false);
    };

    useEffect(() => {
        if (!columns || !selectedProperty) {
            return;
        }

        const emptyInputs = {};

        let prop = { ...selectedProperty };
        while (prop !== undefined && prop !== null) {
            prop.customFields.forEach((customField) => {
                const fieldName = customField.customFieldType.propertyName;
                const columnName = columns.find(x => x.replace(' ', '') === fieldName);
                if (columnName !== undefined) {
                    emptyInputs[columnName] = customField.value;
                }
            })

            prop = prop.parent !== null ? { ...prop.parent } : null;
        }

        setInputValues(emptyInputs);
    }, [columns, selectedProperty])

    useEffect(() => {
        setIsLoading(true);
        approxAPIClient.fetchImportPropertiesTemplate(projectID).then((res) => {
            if (res.isSuccessful === true) {
                const csv = atob(res.data);
                const parsedData = Papa.parse(csv, { header: true });
                setColumns(parsedData.meta.fields);
            } else {
                alert(res.error);
            }

            setIsLoading(false);
        });
    }, [approxAPIClient, projectID]);

    return <Dialog
        open={addPropertyIsOpen}
        onClose={handleAddPropertyClose}
        fullWidth
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                setIsLoading(true);
                event.preventDefault();
                const strProjectID = projectID.toString();
                const csvData = columns.reduce((acc, column) => {
                    acc[column] = inputValues[column] || '';
                    return acc;
                }, {});
                const csvString = Papa.unparse([csvData], { header: true });
                let utf8Encode = new TextEncoder();
                let dataArr = utf8Encode.encode(csvString);
                let base64Data = btoa(String.fromCharCode(...dataArr));
                const input = {
                    base64Data: base64Data,
                    projectID: strProjectID
                }

                approxAPIClient.importProperties(input).then((res) => {
                    if (res.isSuccessful === true) {
                        handleAddPropertySuccess();
                    } else {
                        alert(res.error);
                    }
                    setIsLoading(false);
                    handleAddPropertyClose();
                })
            },
        }}
    >
        <DialogTitle>Add Property</DialogTitle>
        {
            !isLoading && columns !== undefined ? <>
                <DialogContent>
                    {columns.map((column, index) => (
                        <TextField
                            key={index}
                            required
                            margin="dense"
                            label={column}
                            fullWidth
                            value={inputValues[column] || ''}
                            onChange={(e) => handleInputChange(e.target.value, column)}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddPropertyClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                </DialogActions>
            </> : <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Box>
        }
    </Dialog>
}