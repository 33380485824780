import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AzureBlobClient from '../../../AzureBlobClient';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

export default function UploadFilesDialog({ isOpen, setIsOpen, handleSuccess, container, folderNameArgs }) {
    const azureBlobClient = AzureBlobClient();
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);

    const handleClose = () => {
        setFiles([]);
        setIsLoading(false);
        setIsOpen(false);
    };

    const handleSelectFiles = (e) => {
        const selectedFiles = Array.from(e?.target?.files);
        if (selectedFiles.length > 0) {
            setFiles(selectedFiles);
        }
    };

    const handleUploadFiles = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        const uploadPromises = files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = async () => {
                    try {
                        const res = await azureBlobClient.uploadFile(file.name, reader.result, container, folderNameArgs);
                        if (res.isSuccessful) {
                            resolve();
                        } else {
                            reject(res.error);
                        }
                    } catch (error) {
                        reject(error);
                    }
                };
                reader.onerror = (error) => reject(error);
            });
        });

        try {
            await Promise.all(uploadPromises);
            await handleSuccess();
        } catch (error) {
            alert(error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            fullWidth
            PaperProps={{
                component: 'form',
                onSubmit: handleUploadFiles,
            }}
        >
            <DialogTitle>Upload Files</DialogTitle>
            {!isLoading ? (
                <>
                    <DialogContent>
                        <TextField
                            variant="standard"
                            fullWidth
                            type="text"
                            value={files.map(file => file.name).join(', ')}
                            disabled
                            required
                            InputProps={{
                                endAdornment: (
                                    <IconButton component="label">
                                        <FileUploadOutlined />
                                        <input
                                            style={{ display: "none" }}
                                            type="file"
                                            multiple
                                            onChange={handleSelectFiles}
                                            name="fileInput"
                                        />
                                    </IconButton>
                                ),
                            }}
                        />
                        <List>
                            {files.map((file, index) => (
                                <React.Fragment key={index}>
                                    <ListItem>
                                        <ListItemText primary={file.name} />
                                    </ListItem>
                                    {index < files.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit" disabled={files.length === 0}>Import</Button>
                    </DialogActions>
                </>
            ) : (
                <Box sx={{ width: '100%', padding: '5%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </Box>
            )}
        </Dialog>
    );
}
