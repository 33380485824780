import React, { useState, useEffect, useCallback } from 'react';
import ApproxAPIClient from '../../../ApproxAPIClient'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LabelIcon from '@mui/icons-material/Label';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import CreateProjectDialog from './CreateProjectDialog'
import { v4 as guid } from 'uuid';

function ProjectList({ handleProjectSelect, selectedProjectID, handleResetProjectSelect }) {
  const approxAPIClient = ApproxAPIClient();
  const [projects, setProjects] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [createProjectIsOpen, setCreateProjectIsOpen] = useState(false);
  const [rowListRenderID, setRowListRenderID] = useState(guid())

  const handleCreateProjectClickOpen = () => {
    setCreateProjectIsOpen(true);
  };

  const handleCreateProjectSuccess = () => {
    setRowListRenderID(guid());
  }

  const handleListItemClick = useCallback((e, node) => {
    handleProjectSelect(node);
  }, [handleProjectSelect]);

  useEffect(() => {
    setIsLoading(true);
    approxAPIClient.fetchProjects().then((response) => {
      if (response.isSuccessful === true) {
        setProjects(response.data);
      } else {
        alert(response.error);
      }

      if (response?.data?.length > 0) {
        handleListItemClick(null, response.data[0]);
      }
    }).then(() => {
      setIsLoading(false);
    });
  }, [approxAPIClient, handleListItemClick, rowListRenderID])

  const handleProjectDelete = async (projectID) => {
    const input = {
      projectID: projectID
    }

    setIsLoading(true);
    try {
      await approxAPIClient.deleteProject(input).then((response) => {
        if (response.isSuccessful === true) {
          const others = projects.filter((x) => x.id !== projectID);
          setProjects([...others]);
          handleResetProjectSelect();
        } else {
          alert(response.error);
        }
      })
    } catch (error) {
      alert(error);
    }

    setIsLoading(false);
  }

  return (
    <>
      <CreateProjectDialog createProjectIsOpen={createProjectIsOpen} setCreateProjectIsOpen={setCreateProjectIsOpen} handleCreateProjectSuccess={handleCreateProjectSuccess} />
      <Paper style={{ height: '100%' }}>
        <List
          component="nav"
          aria-label="project list"
          style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between', padding: 1 }}>
                <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                  Projects
                </Typography>
                <Button variant="outlined" size="small" disabled={isLoading} onClick={handleCreateProjectClickOpen}>Create</Button>
              </Stack>
            </ListSubheader>
          }
        >
          {!isLoading && projects && projects.length > 0 ? projects.map(x =>
            <ListItemButton
              selected={x.id === selectedProjectID}
              onClick={(e) => handleListItemClick(e, x)}
              key={x.id}
            >
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
              <ListItemText
                primary={x.name}
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: 'medium',
                  lineHeight: '50px',
                  mb: '2px',
                  color: 'black'
                }}

              />
              <IconButton edge="end" aria-label="delete" onClick={() => handleProjectDelete(x.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemButton>
          ) : !isLoading && projects && projects.length === 0 ?
            <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="center">
              There is no project!
            </Typography> : <Box sx={{ width: '100%', padding: '5%' }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>}
        </List>
      </Paper>
    </>

  );
}

export default ProjectList;