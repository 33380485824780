import { config } from './config';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useCallback, useMemo } from 'react';

const ApproxAPIClient = () => {
    const { getAccessTokenSilently } = useAuth0();
    const baseURL = config.api.url;

    const callApi = useCallback(async (endpoint, params, body, method) => {
        let result = {
            data: undefined,
            isSuccessful: false,
            error: undefined
        }

        try {
            const token = await getAccessTokenSilently();
            let url = `${baseURL}/${endpoint}`;
            if (params) {
                url = `${url}?${new URLSearchParams(params)}`
            }
            var response = await axios({
                method: method,
                url: url,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                data: body ? JSON.stringify(body) : null,
            });

            result.data = response.data;
            result.isSuccessful = true;
            return result;
        } catch (error) {
            result.error = error.response ? error.response.data.Error : error.message;
            return result;
        }
    }, [getAccessTokenSilently, baseURL]);

    const approxAPIClient = useMemo(() => {
        const endpoints = {
            GetWorkTypeByID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetWorkTypeByIDAsync",
                Method: "GET"
            },
            GetScreenTypeColorByID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetScreenTypeColorByIDAsync",
                Method: "GET"
            },
            GetWorkOrderStateByWorkOrderID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderStateByWorkOrderIDAsync",
                Method: "GET"
            },
            GetWorkTypeNamesByWorkGroupTypeID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetWorkTypeNamesByWorkGroupTypeIDAsync",
                Method: "GET"
            },
            GetPropertyTreeByPropertyID: {
                Controller: "Project",
                Endpoint: "GetPropertyTreeByChildPropertyIDAsync",
                Method: "GET"
            },
            GetWorkOrderByID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderByIDAsync",
                Method: "GET"
            },
            GetProjectPropertiesByProjectID: {
                Controller: "Project",
                Endpoint: "GetProjectPropertiesByProjectIDAsync",
                Method: "GET"
            },
            GetProjects: {
                Controller: "Project",
                Endpoint: "GetProjectsAsync",
                Method: "GET"
            },
            GetNextPrevAssignableWorkOrderIDByWorkOrderID: {
                Controller: "WorkOrder",
                Endpoint: "GetNextPrevAssignableWorkOrderIDByWorkOrderIDAsync",
                Method: "GET"
            },
            GetWorkOrderRowsByWorkOrderID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderRowsByWorkOrderIDAsync",
                Method: "GET"
            },
            GetRowTypesByScreenTypeID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetRowTypesByScreenTypeIDAsync",
                Method: "GET"
            },
            GetCalculationAreaTypeIDsByWorkTypeID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetCalculationAreaTypeIDsByWorkTypeIDAsync",
                Method: "GET"
            },
            GetCalculationAreaTypeByID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetCalculationAreaTypeByIDAsync",
                Method: "GET"
            },
            GetCalculationAreaResultByWorkOrderIDCalculationAreaTypeID: {
                Controller: "WorkOrder",
                Endpoint: "GetCalculationAreaResultByWorkOrderIDCalculationAreaTypeIDAsync",
                Method: "GET"
            },
            GetCalculationAreaGroupPricingsByCalculationAreaGroupID: {
                Controller: "WorkOrder",
                Endpoint: "GetCalculationAreaGroupPricingsByCalculationAreaGroupIDAsync",
                Method: "GET"
            },
            GetWorkGroupTypesByTakeoffTemplateID: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetWorkGroupTypesByTakeoffTemplateIDAsync",
                Method: "GET"
            },
            GetTenantTakeoffTemplates: {
                Controller: "TakeoffTemplate",
                Endpoint: "GetTenantTakeoffTemplatesAsync",
                Method: "GET"
            },
            GetWorkOrdersByWorkTypeIDProjectID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrdersByWorkTypeIDProjectIDAsync",
                Method: "GET"
            },
            GetAllTenantUsers: {
                Controller: "WorkOrder",
                Endpoint: "GetAllTenantUsersAsync",
                Method: "GET"
            },
            UpsertRow: {
                Controller: "WorkOrder",
                Endpoint: "UpsertRowAsync",
                Method: "PUT"
            },
            DeleteRow: {
                Controller: "WorkOrder",
                Endpoint: "DeleteRowAsync",
                Method: "DELETE"
            },
            AssignWorkOrdersToUser: {
                Controller: "WorkOrder",
                Endpoint: "AssignWorkOrdersToUserAsync",
                Method: "PUT"
            },
            ChangeWorkOrderState: {
                Controller: "WorkOrder",
                Endpoint: "ChangeWorkOrderStateAsync",
                Method: "PUT"
            },
            CreateWorkOrderCalculationAreaGroup: {
                Controller: "WorkOrder",
                Endpoint: "CreateWorkOrderCalculationAreaGroupAsync",
                Method: "POST"
            },
            DeleteWorkOrderCalculationAreaGroup: {
                Controller: "WorkOrder",
                Endpoint: "DeleteWorkOrderCalculationAreaGroupAsync",
                Method: "DELETE"
            },
            AssignRowToWorkOrderCalculationAreaGroup: {
                Controller: "WorkOrder",
                Endpoint: "AssignRowToWorkOrderCalculationAreaGroupAsync",
                Method: "PUT"
            },
            CreateProject: {
                Controller: "Project",
                Endpoint: "CreateProjectAsync",
                Method: "POST"
            },
            DeleteProject: {
                Controller: "Project",
                Endpoint: "DeleteProjectAsync",
                Method: "DELETE"
            },
            DeleteProperty: {
                Controller: "Project",
                Endpoint: "DeletePropertyAsync",
                Method: "DELETE"
            },
            ImportProperties: {
                Controller: "Project",
                Endpoint: "ImportPropertiesAsync",
                Method: "POST"
            },
            GetImportPropertiesTemplate: {
                Controller: "Project",
                Endpoint: "GetImportPropertiesTemplateAsync",
                Method: "GET"
            },
            GetWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeIDAsync",
                Method: "GET"
            },
            GetWorkOrderCalculationAreaGroupsByWorkOrderID: {
                Controller: "WorkOrder",
                Endpoint: "GetWorkOrderCalculationAreaGroupsByWorkOrderIDAsync",
                Method: "GET"
            }
        }

        const fetchWorkTypeNamesByWorkGroupTypeID = async (workGroupTypeID) => {
            const endpoint = endpoints.GetWorkTypeNamesByWorkGroupTypeID;
            const params = { workGroupTypeID: workGroupTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchNextPrevAssignableWorkOrderIDByWorkOrderID = async (workOrderID, nextPrevious) => {
            const endpoint = endpoints.GetNextPrevAssignableWorkOrderIDByWorkOrderID;
            const params = { workOrderID: workOrderID, nextPrevious: nextPrevious };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkTypeByID = async (workTypeID) => {
            const endpoint = endpoints.GetWorkTypeByID;
            const params = { workTypeID: workTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchProppertyTreeByPropertyID = async (propertyID) => {
            const endpoint = endpoints.GetPropertyTreeByPropertyID;
            const params = { ChildPropertyID: propertyID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderByID = async (workOrderID) => {
            const endpoint = endpoints.GetWorkOrderByID;
            const params = { workOrderID: workOrderID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchProjectPropertiesByProjectID = async (projectID, workTypeID) => {
            const endpoint = endpoints.GetProjectPropertiesByProjectID;
            let params = {};
            if (workTypeID) {
                params = { projectID: projectID, workTypeID: workTypeID };
            } else {
                params = { projectID: projectID };
            }

            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchProjects = async () => {
            const endpoint = endpoints.GetProjects;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, null, endpoint.Method);
        };

        const upsertRow = async (input) => {
            const endpoint = endpoints.UpsertRow;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const createWorkOrderCalculationAreaGroup = async (input) => {
            const endpoint = endpoints.CreateWorkOrderCalculationAreaGroup;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteWorkOrderCalculationAreaGroup = async (input) => {
            const endpoint = endpoints.DeleteWorkOrderCalculationAreaGroup;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const assignRowToWorkOrderCalculationAreaGroup = async (input) => {
            const endpoint = endpoints.AssignRowToWorkOrderCalculationAreaGroup;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteRow = async (input) => {
            const endpoint = endpoints.DeleteRow;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const createProject = async (input) => {
            const endpoint = endpoints.CreateProject;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteProject = async (input) => {
            const endpoint = endpoints.DeleteProject;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const deleteProperty = async (input) => {
            const endpoint = endpoints.DeleteProperty;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const importProperties = async (input) => {
            const endpoint = endpoints.ImportProperties;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const assignWorkOrdersToUser = async (input) => {
            const endpoint = endpoints.AssignWorkOrdersToUser;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const changeWorkOrderState = async (input) => {
            const endpoint = endpoints.ChangeWorkOrderState;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, input, endpoint.Method);
        };

        const fetchScreenTypeColorByID = async (id) => {
            const endpoint = endpoints.GetScreenTypeColorByID;
            const params = { id: id };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderStateByWorkOrderID = async (workOrderID) => {
            const endpoint = endpoints.GetWorkOrderStateByWorkOrderID;
            const params = { workOrderID: workOrderID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderRowsByWorkOrderID = async (workOrderID) => {
            const endpoint = endpoints.GetWorkOrderRowsByWorkOrderID;
            const params = { workOrderID: workOrderID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchRowTypesByScreenTypeID = async (screenTypeID) => {
            const endpoint = endpoints.GetRowTypesByScreenTypeID;
            const params = { screenTypeID: screenTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchCalculationAreaTypeIDsByWorkTypeID = async (workTypeID) => {
            const endpoint = endpoints.GetCalculationAreaTypeIDsByWorkTypeID;
            const params = { workTypeID: workTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchCalculationAreaTypeByID = async (calculationAreaTypeID) => {
            const endpoint = endpoints.GetCalculationAreaTypeByID;
            const params = { calculationAreaTypeID: calculationAreaTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchCalculationAreaResultByWorkOrderIDCalculationAreaTypeID = async (workOrderID, calculationAreaTypeID) => {
            const endpoint = endpoints.GetCalculationAreaResultByWorkOrderIDCalculationAreaTypeID;
            const params = { workOrderID: workOrderID, calculationAreaTypeID: calculationAreaTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchCalculationAreaGroupPricingsByCalculationAreaGroupID = async (propertyID, calculationAreaTypeID) => {
            const endpoint = endpoints.GetCalculationAreaGroupPricingsByCalculationAreaGroupID;
            const params = { propertyID: propertyID, calculationAreaTypeID: calculationAreaTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkGroupTypesByTakeoffTemplateID = async (takeoffTemplateID) => {
            const endpoint = endpoints.GetWorkGroupTypesByTakeoffTemplateID;
            const params = { takeoffTemplateID: takeoffTemplateID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchTenantTakeoffTemplates = async () => {
            const endpoint = endpoints.GetTenantTakeoffTemplates;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, null, endpoint.Method);
        };

        const fetchWorkOrdersByWorkTypeIDProjectID = async (workTypeID, projectID, type) => {
            const endpoint = endpoints.GetWorkOrdersByWorkTypeIDProjectID;
            const params = { workTypeID: workTypeID, projectID: projectID, workOrderType: type };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchAllTenantUsers = async () => {
            const endpoint = endpoints.GetAllTenantUsers;
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, null, null, endpoint.Method);
        };

        const fetchImportPropertiesTemplate = async (projectID) => {
            const endpoint = endpoints.GetImportPropertiesTemplate;
            const params = { projectID: projectID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeID = async (workOrderID, calculationAreaTypeID) => {
            const endpoint = endpoints.GetWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeID;
            const params = { workOrderID: workOrderID, calculationAreaTypeID: calculationAreaTypeID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        const fetchWorkOrderCalculationAreaGroupsByWorkOrderID = async (workOrderID) => {
            const endpoint = endpoints.GetWorkOrderCalculationAreaGroupsByWorkOrderID;
            const params = { workOrderID: workOrderID };
            const url = `${endpoint.Controller}/${endpoint.Endpoint}`;
            return await callApi(url, params, null, endpoint.Method);
        };

        return {
            fetchWorkTypeByID,
            fetchProppertyTreeByPropertyID,
            fetchWorkOrderByID,
            fetchProjectPropertiesByProjectID,
            upsertRow,
            deleteRow,
            fetchProjects,
            fetchWorkTypeNamesByWorkGroupTypeID,
            fetchScreenTypeColorByID,
            fetchWorkOrderStateByWorkOrderID,
            fetchNextPrevAssignableWorkOrderIDByWorkOrderID,
            assignWorkOrdersToUser,
            fetchWorkOrderRowsByWorkOrderID,
            fetchRowTypesByScreenTypeID,
            fetchCalculationAreaTypeIDsByWorkTypeID,
            fetchCalculationAreaTypeByID,
            fetchCalculationAreaResultByWorkOrderIDCalculationAreaTypeID,
            fetchCalculationAreaGroupPricingsByCalculationAreaGroupID,
            fetchWorkGroupTypesByTakeoffTemplateID,
            fetchTenantTakeoffTemplates,
            createProject,
            importProperties,
            fetchWorkOrdersByWorkTypeIDProjectID,
            fetchAllTenantUsers,
            fetchImportPropertiesTemplate,
            fetchWorkOrderCalculationAreaGroupsByWorkOrderIDCalculationAreaTypeID,
            createWorkOrderCalculationAreaGroup,
            deleteWorkOrderCalculationAreaGroup,
            assignRowToWorkOrderCalculationAreaGroup,
            fetchWorkOrderCalculationAreaGroupsByWorkOrderID,
            deleteProject,
            deleteProperty,
            changeWorkOrderState
        };
    }, [callApi]);

    return approxAPIClient;
};

export default ApproxAPIClient;