import React, { useEffect, useState, useCallback } from 'react';
import Row from './Row';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';

export default function PageRowList({ isReadOnly, workOrderID, screenTypeID, renewRowListRenderID, pricingGroupsRenderID }) {
    const [propertyRows, setPropertyRows] = useState();
    const [rowTypes, setRowTypes] = useState();
    const [activeRowIndex, setActiveRowIndex] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [workOrderCalculationAreaGroups, setWorkOrderCalculationAreaGroups] = useState();
    const emptyRowDivRef = React.createRef();
    const approxAPIClient = ApproxAPIClient();

    const workOrderCalculationAreaGroupSubmit = (rowID, assignees) => {
        const input = {
            workOrderCalculationAreaGroupIDs: assignees.map((x) => x.workOrderCalculationAreaGroupID),
            rowID: rowID
        }

        setIsLoading(true);
        approxAPIClient.assignRowToWorkOrderCalculationAreaGroup(input).then((response) => {
            if (response.isSuccessful === true) {
                let row = propertyRows.find(x => x.data.id === rowID);
                row.data.calculationAreaResults.forEach((result) => {
                    const assignee = assignees.find((assignee) => assignee.calculationAreaTypeID === result.calculationAreaTypeID);
                    result.workOrderCalculationAreaGroupID = assignee.workOrderCalculationAreaGroupID;
                    result.workOrderCalculationAreaGroupName = workOrderCalculationAreaGroups.find(z => z.id === assignee.workOrderCalculationAreaGroupID).name;
                })

                const otherRows = propertyRows.filter(x => x.data.id !== rowID);
                let total = [...otherRows, row];
                total.sort((a, b) => a.rowIndex - b.rowIndex);
                setPropertyRows([...total]);
            } else {
                alert(response.error);
            }
        }).then(() => {
            setIsLoading(false);
            renewRowListRenderID();
        });
    }

    const addEmptyRowOrSelectNextRow = async (e, updatedRoomRows) => {
        setPropertyRows(updatedRoomRows ?? []);
        let lastRowIndex = updatedRoomRows?.map((row) => row.rowIndex)?.sort((x, y) => x - y)?.pop() ?? 0;
        if (activeRowIndex === lastRowIndex || propertyRows.length === 0) {
            let newRowIndex = lastRowIndex + 1;
            const newRowData = {
                rowIndex: newRowIndex,
                data: {
                    rowTypeID: undefined,
                    inputFields: undefined,
                    calculationAreaResults: [],
                    id: undefined,
                    createdAt: new Date().toISOString()
                }
            };

            let updatedRows = [...(updatedRoomRows ?? []), newRowData];
            updatedRows.sort((a, b) => Date.parse(a.data.createdAt) - Date.parse(b.data.createdAt));
            setPropertyRows(updatedRows);
            setActiveRowIndex(newRowIndex);
        } else {
            const nextRowIndex = propertyRows.map((row) => row.rowIndex).filter(x => x > activeRowIndex).sort((x, y) => x - y).shift();
            setActiveRowIndex(nextRowIndex);
        }
    };

    const prepareRoomRows = useCallback((rows) => {
        const sortedRows = rows.map((row, index) => ({ rowIndex: index + 1, data: row }));
        setActiveRowIndex(sortedRows.map((row) => row.rowIndex).pop());
        return sortedRows;
    }, [setActiveRowIndex]);

    useEffect(() => {
        try {
            setIsLoading(true);
            approxAPIClient.fetchWorkOrderRowsByWorkOrderID(workOrderID).then((response) => {
                if (response.isSuccessful === true) {
                    let rows = prepareRoomRows(response.data);
                    setPropertyRows(rows);
                } else {
                    alert(response.error);
                }

            }).then(() => {
                setIsLoading(false);
                renewRowListRenderID();
            });
        } catch (error) {
            console.error('Error fetching property data:', error);
        }
    }, [approxAPIClient, workOrderID, prepareRoomRows, renewRowListRenderID])

    useEffect(() => {
        try {
            setIsLoading(true);
            approxAPIClient.fetchWorkOrderCalculationAreaGroupsByWorkOrderID(workOrderID).then((response) => {
                if (response.isSuccessful === true) {
                    setWorkOrderCalculationAreaGroups(response.data);
                } else {
                    alert(response.error);
                }

            }).then(() => {
                setIsLoading(false);

            });
        } catch (error) {
            console.error('Error fetching property data:', error);
        }
    }, [approxAPIClient, workOrderID, pricingGroupsRenderID])

    useEffect(() => {
        try {
            setIsLoading(true);
            approxAPIClient.fetchRowTypesByScreenTypeID(screenTypeID).then((response) => {
                if (response.isSuccessful === true) {
                    setRowTypes(response.data);
                } else {
                    alert(response.error);
                }

            }).then(() => {
                setIsLoading(false);
            });
        } catch (error) {
            console.error('Error fetching property data:', error);
        }
    }, [approxAPIClient, screenTypeID])

    const emptyRoomKeyPress = async (e) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            await emptyRoomFunc(e);
        }
    };

    const upsertRow = async (e, upsertRowInput) => {
        setIsLoading(true);
        let upsertRowRes = await approxAPIClient.upsertRow(upsertRowInput);
        if (upsertRowRes.isSuccessful) {
            setIsLoading(false);
            let existingRow = propertyRows.find(r => r.data.id === upsertRowInput.id);
            const otherRows = propertyRows.filter(r => r.data.id !== upsertRowInput.id);
            if (!existingRow) {
                existingRow = {
                    rowIndex: otherRows.sort((a, b) => a.rowIndex - b.rowIndex).pop()?.rowIndex ?? 0 + 1
                }
            }
            let newRow = { ...existingRow };
            newRow.data = null;
            newRow.data = { ...upsertRowRes.data };
            let updatedRows = [...otherRows, newRow];
            updatedRows.sort((a, b) => Date.parse(a.data.createdAt) - Date.parse(b.data.createdAt));
            await addEmptyRowOrSelectNextRow(e, updatedRows);
            renewRowListRenderID();
        } else {
            alert(upsertRowRes.error);
        }
    }

    const deleteRow = async (value) => {
        const deletedRow = propertyRows.find((row) => row.rowIndex === value);
        const deleted = deletedRow.data;
        if (deleted?.id) {
            const input = {
                rowID: deleted.id
            }
            setIsLoading(true);
            let deleteRes = await approxAPIClient.deleteRow(input)
            setIsLoading(false);
            renewRowListRenderID();
            if (!deleteRes.isSuccessful) {
                alert(deleteRes.error);
            }
        }

        const unDeleted = propertyRows.filter((row) => row.rowIndex !== value)
            .sort((a, b) => Date.parse(a.data.createdAt) - Date.parse(b.data.createdAt));
        setPropertyRows([...unDeleted]);

        let possibleActiveRowIndex = deletedRow.rowIndex - 1;
        if (!unDeleted.some(x => x.rowIndex === possibleActiveRowIndex)) {
            possibleActiveRowIndex = unDeleted.pop()?.rowIndex;
        }
        setActiveRowIndex(possibleActiveRowIndex);
    };

    const emptyRoomFunc = async (e) => {
        await addEmptyRowOrSelectNextRow(e, propertyRows);
        setActiveRowIndex(1);
    }

    useEffect(() => {
        if (propertyRows && propertyRows.length === 0 && emptyRowDivRef.current) {
            emptyRowDivRef.current.focus();
        }
    }, [propertyRows, emptyRowDivRef, isReadOnly])

    return (
        <>
            {propertyRows && propertyRows.length > 0 && rowTypes ? (
                <Box sx={{ padding: '5px', overflowX: 'hidden', overflowY: 'auto', height: '100%' }}>
                    {isLoading ? <Box sx={{ width: '100%', padding: '5%' }}>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </Box> : propertyRows.map((row) => {
                        return (
                            <Row
                                key={`Row#${row.data.id}`}
                                upsertRow={upsertRow}
                                rowIndex={row.rowIndex}
                                row={row.data}
                                isReadOnly={isReadOnly}
                                activeRowIndex={activeRowIndex}
                                workOrderID={workOrderID}
                                rowTypes={rowTypes}
                                deleteRow={() => deleteRow(row.rowIndex)}
                                setActiveRowIndex={setActiveRowIndex}
                                workOrderCalculationAreaGroups={workOrderCalculationAreaGroups}
                                workOrderCalculationAreaGroupSubmit={workOrderCalculationAreaGroupSubmit}
                            />
                        );
                    })}
                </Box>
            ) : !isLoading && propertyRows && propertyRows.length === 0 ? (
                <Alert sx={{ opacity: isReadOnly ? 0.60 : 1, pointerEvents: isReadOnly ? 'none' : 'initial' }} icon={<AddIcon fontSize="inherit" />} severity="error" onClick={isReadOnly ? undefined : emptyRoomFunc} tabIndex="0" ref={emptyRowDivRef} onKeyDown={isReadOnly ? undefined : emptyRoomKeyPress}>
                    Please press enter to add a row
                </Alert>
            ) : <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Box>}
        </>
    );
}