import React from 'react';
import Layout from '../common/components/Layout';


export default function AdminPage() {

    return (
        <Layout>
            <iframe title='Pricing Parser' src="https://approx.retool.com/apps/Pricing%20Parser" width="100%" height="100%"></iframe>
        </Layout>
    );
}