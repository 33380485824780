import React, { Component } from 'react';
import { withAuth0 } from "@auth0/auth0-react";
import { Navigate } from 'react-router-dom';

export default class LogoutPage extends Component {
  
  componentDidMount(){
    const { logout } = withAuth0;
    logout();
  }
  
  render(){
      return <Navigate to='/'/>
  }
};