import React, { useEffect, useState, useCallback } from 'react';
import InputFieldDataType from '../../../constants/InputFieldDataType';
import TextField from '@mui/material/TextField';


export default function InputField({ inputFieldType, data, fillInputFieldData, selectedRowType, rowIndex, inputFieldReference, setNextInputFieldActive }) {
  const [inputNumericValue, setInputNumericValue] = useState(0);
  const [inputTextValue, setInputTextValue] = useState('');

  const prepareValue = useCallback((rawValue) => {
    if (inputFieldType.dataType === InputFieldDataType.Numeric) {
      if (rawValue === '') {
        rawValue = '0';
      }

      let float = Number.parseFloat(rawValue);
      return isNaN(float) ? '' : float.toFixed(inputFieldType.precision);
    }

    if (inputFieldType.dataType === InputFieldDataType.Text) {
      return rawValue?.toString() ?? '';
    }
  }, [inputFieldType.dataType, inputFieldType.precision]);

  const [inputValue, setInputValue] = useState(() => {
    return prepareValue(data?.value);
  });

  const setFixedValue = () => {
    let value = inputFieldType.dataType === InputFieldDataType.Numeric ? inputNumericValue
      : inputFieldType.dataType === InputFieldDataType.Text ? inputTextValue
        : undefined;

    setInputValue(value);
  };

  useEffect(() => {
    if (!inputFieldType) {
      return;
    }

    let value = prepareValue(inputValue);

    if (inputFieldType.dataType === InputFieldDataType.Numeric) {
      let float = Number.parseFloat(value);

      if (float !== inputNumericValue) {
        setInputNumericValue(float);
      }
    }

    if (inputFieldType.dataType === InputFieldDataType.Text) {
      if (value !== inputTextValue) {
        setInputTextValue(value);
      }
    }

    fillInputFieldData(
      inputFieldType.dataType === InputFieldDataType.Text ? value : '',
      inputFieldType.dataType === InputFieldDataType.Numeric ? Number.parseFloat(value) : 0,
      inputFieldType);

  }, [inputValue, prepareValue, inputTextValue, inputNumericValue, fillInputFieldData, inputFieldType])

  return (<TextField
    multiline={inputFieldType.dataType === InputFieldDataType.Text}
    rows={3}
    label={inputFieldType.description}
    onBlur={() => setFixedValue()}
    inputRef={inputFieldReference}
    onKeyDownCapture={(e) => setNextInputFieldActive(e, inputFieldType.sorting)}
    type={inputFieldType.dataType === InputFieldDataType.Text ? "text" : "number"}
    className={inputFieldType.dataType === InputFieldDataType.Text ? "left" : ""}
    value={inputValue}
    size="small"
    onChange={(e) => setInputValue(e.target.value)}
    InputLabelProps={{
      style: {
        color: selectedRowType.color
      }
    }}
    InputProps={{
      style: {
        color: 'black'
      }
    }}
    sx={{
      width: inputFieldType.dataType === InputFieldDataType.Text ? '100%' : '20%',
      height: inputFieldType.dataType === InputFieldDataType.Text ? '100%' : '50%',
      marginLeft: 1,
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: selectedRowType.color,
        },
      },
    }}
  />
  );
}