import { React, useState, useEffect } from 'react';
import StatusBar from './StatusBar';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import SegmentIcon from '@mui/icons-material/Segment';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';

export default function Header({ screenTypeID, workOrderID, propertyID, openWorkOrderListDrawer, rowListRenderID }) {
    const approxAPIClient = ApproxAPIClient();
    const [propertyTree, setPropertyTree] = useState();
    const [screenTypeColor, setScreenTypeColor] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        approxAPIClient.fetchScreenTypeColorByID(screenTypeID).then((response) => {
            if (response.isSuccessful === true) {
                setScreenTypeColor(response.data);
            } else {
                alert(response.error);
            }

        }).then(() => {
            setIsLoading(false);
        })
    }, [approxAPIClient, screenTypeID])

    useEffect(() => {
        setIsLoading(true);
        approxAPIClient.fetchProppertyTreeByPropertyID(propertyID).then((response) => {
            if (response.isSuccessful === true) {
                setPropertyTree(response.data);
            } else {
                alert(response.error);
            }

        }).then(() => {
            setIsLoading(false);
        })
    }, [propertyID, approxAPIClient])

    const getProperties = (customFields) => {

        const nameList = [];

        for (const customField of customFields) {
            const propertyTypeID = customField.customFieldType.propertyTypeID;

            let prp = nameList.find(x => x.propertyTypeID === propertyTypeID);
            if (prp === undefined) {
                nameList.push({ propertyTypeID: propertyTypeID, index: customFields.indexOf(customField), customFields: [customField] });
            } else {
                prp.customFields.push(customField);
            }
        }

        nameList.sort((a, b) => a.index - b.index);

        return nameList.map(prp => {
            const isLast = nameList.indexOf(prp) + 1 === nameList.length;
            return (<Typography color={isLast ? "white" : 'grey'} key={prp.propertyTypeID}> {prp.customFields.map(x => x.value).join(' - ')} </Typography>)
        });
    }

    return ((isLoading || !screenTypeColor || !propertyTree) ? <Card sx={{ width: '100%', padding: '5%', height: '100%' }}>
    </Card> : <Card sx={{ backgroundColor: screenTypeColor?.color ?? 'orange', height: '100%' }}>
        <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left', padding: 0 }}>
            <Box style={{ width: '80%', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 5 }}>
                    {propertyTree ? getProperties(propertyTree.customFields) : []}
                </Breadcrumbs>
            </Box>
            <Box style={{ width: '5%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Button onClick={openWorkOrderListDrawer} variant={'outlined'}>
                    <SegmentIcon sx={{ color: 'white' }} />
                </Button>
            </Box>
            <Box style={{ width: '15%', padding: '1px', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <StatusBar workOrderID={workOrderID} rowListRenderID={rowListRenderID} />
            </Box>
        </CardContent>
    </Card>

    );
}