import { BlobServiceClient } from '@azure/storage-blob';
import { config } from './config';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

const AzureBlobClient = () => {

    const { getIdTokenClaims } = useAuth0();

    const client = useMemo(() => {

        const storageURL = `https://stapprox.blob.core.windows.net?${config.storage.token}`
        const blobServiceClient = new BlobServiceClient(storageURL);
        const tenantFilesContainerClient = blobServiceClient.getContainerClient(config.storage.tenantFilesContainer);

        const downloadBlob = async (blobName, fileName, contentType) => {
            const blobClient = tenantFilesContainerClient.getBlobClient(blobName);
            const res = await blobClient.download();
            const body = await res.blobBody;
            const fileReader = new FileReader();
            await new Promise((resolve, reject) => {
                fileReader.onloadend = (ev) => {
                    resolve(ev.target.result);
                };
                fileReader.onerror = reject;
                fileReader.readAsArrayBuffer(body);
            });

            const base64 = btoa(String.fromCharCode(...new Uint8Array(fileReader.result)));
            var a = document.createElement("a");
            a.href = `data:${contentType};base64,${base64}`;
            a.download = fileName;
            a.click();
            return;
        }

        const deleteBlob = async (blobName) => {
            const blobClient = tenantFilesContainerClient.getBlobClient(blobName);
            await blobClient.delete();
        }

        const uploadFile = async (fileName, file, containerName, folderNameArgs) => {
            const args = Array.from(folderNameArgs);
            const claims = await getIdTokenClaims();
            const blobName = `${containerName}/${claims.org_id}/${args.join('/')}/${fileName}`;
            const blockBlobClient = tenantFilesContainerClient.getBlockBlobClient(blobName);
            await blockBlobClient.uploadData(file);
            return {
                isSuccessful: true
            }
        }

        const deleteAll = async (containerName, folderNameArgs) => {
            const claims = await getIdTokenClaims();
            const args = Array.from(folderNameArgs);
            const folderName = `${containerName}/${claims.org_id}/${args.join('/')}`;
            const listOptions = {
                prefix: folderName
            };

            for await (const blob of tenantFilesContainerClient.listBlobsFlat(listOptions)) {
                await deleteBlob(blob.name);
            }
        }

        const getOriginalFilesByProjectIDWorkGroupTypeID = async (projectID, workGroupTypeID) => {
            let response = {
                data: undefined,
                isSuccessful: false,
                error: undefined
            }

            try {
                const claims = await getIdTokenClaims();
                const workGroupOriginalFilesBlobName = `original-files/${claims.org_id}/${projectID}/${workGroupTypeID}/`
                let blobs = [];
                const listOptions = {
                    prefix: workGroupOriginalFilesBlobName
                };

                for await (const blob of tenantFilesContainerClient.listBlobsFlat(listOptions)) {
                    blobs.push(blob);
                }

                response.isSuccessful = true;
                response.data = blobs.map((blob) => {
                    const splittedName = blob.name.split('/');
                    const tenantID = splittedName[1];
                    const projectID = splittedName[2];
                    const workGroupTypeID = splittedName[3];
                    const fileFullName = splittedName[4];
                    const ext = fileFullName.split('.').pop();

                    return ({
                        tenantID: tenantID,
                        projectID: projectID,
                        workGroupTypeID: workGroupTypeID,
                        contentType: blob.properties.contentType,
                        fileFullName: fileFullName,
                        ext: ext,
                        id: blob.name,
                        download: () => downloadBlob(blob.name, fileFullName, blob.properties.contentType),
                        delete: () => deleteBlob(blob.name)
                    });
                });
            } catch (error) {
                response.error = error;
            }

            return response;

        }

        const getDrawingsByProjectIDWorkTypeID = async (projectID, workTypeID) => {
            let response = {
                data: undefined,
                isSuccessful: false,
                error: undefined
            }

            try {
                const claims = await getIdTokenClaims();
                const workOrderFilesBlobName = `drawings/${claims.org_id}/${projectID}/${workTypeID}/`
                let blobs = [];
                const listOptions = {
                    prefix: workOrderFilesBlobName
                };

                for await (const blob of tenantFilesContainerClient.listBlobsFlat(listOptions)) {
                    blobs.push(blob);
                }

                response.isSuccessful = true;
                response.data = blobs.map((blob) => {
                    const splittedName = blob.name.split('/');
                    const tenantID = splittedName[1];
                    const projectID = splittedName[2];
                    const workTypeID = splittedName[3];
                    const workOrderID = splittedName[4];
                    const fileFullName = splittedName[5];
                    const ext = fileFullName.split('.').pop();

                    return ({
                        tenantID: tenantID,
                        projectID: projectID,
                        workOrderID: workOrderID,
                        workTypeID: workTypeID,
                        contentType: blob.properties.contentType,
                        fileFullName: fileFullName,
                        ext: ext,
                        id: blob.name,
                        download: () => downloadBlob(blob.name, fileFullName, blob.properties.contentType),
                    });
                });
            } catch (error) {
                response.error = error;
            }

            return response;
        }

        return {
            getOriginalFilesByProjectIDWorkGroupTypeID,
            uploadFile,
            getDrawingsByProjectIDWorkTypeID,
            deleteAll
        }
    }, [getIdTokenClaims])

    return client;

}

export default AzureBlobClient;
