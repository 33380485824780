const env = process.env.REACT_APP_ENV === undefined ? 'development' : process.env.REACT_APP_ENV;

export const config = {
    env,
    isDev: env === 'development',
    isProduction: env === 'production',
    auth0: {
        domain: "approx.eu.auth0.com",
        clientId: "GvbFhMRhVt5Tmi3kLPzcBpPhaDl2YFgM",
        audience: 'https://approx.azurewebsites.net',
        adminRole: 'ApplicationAdmin'
    }
};