import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom'
import './TakeOffPage.css';
import Header from './components/Header';
import PagePricingSectionList from './components/PagePricingSectionList';
import PageRowList from './components/PageRowList'
import ApproxAPIClient from '../../ApproxAPIClient'
import WorkOrderList from '../workOrder/components/WorkOrderList';
import Drawer from '@mui/material/Drawer';
import NextPrevious from '../../constants/NextPrevious';
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { v4 as guid } from 'uuid';
import Layout from '../common/components/Layout';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import WorkOrderState from '../../constants/WorkOrderState';

export default function TakeOffPage() {
  const [searchParams] = useSearchParams();
  const [workOrder, setWorkOrder] = useState();
  const [workType, setWorkType] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isWorkOrderListDrawerOpen, setIsWorkOrderListDrawerOpen] = useState(false);
  const [rowListRenderID, setRowListRenderID] = useState(guid())
  const [pricingGroupsRenderID, setPricingGroupsRenderID] = useState(guid())
  const approxAPIClient = ApproxAPIClient();
  const workOrderID = searchParams.get("workOrderID");
  const projectID = searchParams.get("projectID");
  const navigate = useNavigate();

  const renewRowListRenderID = useCallback(() => {
    setRowListRenderID(guid());
  }, [setRowListRenderID]);

  const renewPricingGroupsRenderID = useCallback(() => {
    setPricingGroupsRenderID(guid());
  }, [setPricingGroupsRenderID]);


  useEffect(() => {
    try {
      setIsLoading(true);
      approxAPIClient.fetchWorkOrderByID(workOrderID).then((response) => {
        if (response.isSuccessful === true) {
          setWorkOrder(response.data);
        } else {
          alert(response.error);
        }

      }).then(() => {
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error fetching property data:', error);
    }
  }, [workOrderID, approxAPIClient])

  const navigateToWorkOrder = (workOrderID) => {
    const queryParams = {
      workOrderID: workOrderID,
      projectID: projectID
    };

    navigate(`?${new URLSearchParams(queryParams).toString()}`);
  }

  const handleNextPrevAvailableWorkOrder = async (nextPreviousVal) => {
    await approxAPIClient.fetchNextPrevAssignableWorkOrderIDByWorkOrderID(workOrderID, nextPreviousVal).then((response) => {
      if (response.isSuccessful === true) {
        if (response.data === workOrderID) {
          return;
        }

        navigateToWorkOrder(response.data);
      } else {
        alert(response.error);
      }

    })
  }

  useKeyboardShortcut(
    ["Alt", "PageUp"],
    shortcutKeys => handleNextPrevAvailableWorkOrder(NextPrevious.Next),
    {
      overrideSystem: true,
      ignoreInputFields: false,
      repeatOnHold: false
    }
  );

  useKeyboardShortcut(
    ["Alt", "PageDown"],
    shortcutKeys => handleNextPrevAvailableWorkOrder(NextPrevious.Previous),
    {
      overrideSystem: true,
      ignoreInputFields: false,
      repeatOnHold: false
    }
  );

  useEffect(() => {
    if (!workOrder) {
      return;
    }

    try {
      setIsLoading(true);
      approxAPIClient.fetchWorkTypeByID(workOrder.workTypeID).then((response) => {
        if (response.isSuccessful === true) {
          setWorkType(response.data);
        } else {
          alert(response.error);
        }

      }).then(() => {
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error fetching property data:', error);
    }
  }, [approxAPIClient, workOrder])

  const openWorkOrderListDrawer = () => {
    setIsWorkOrderListDrawerOpen(true);
  }

  const closeWorkOrderListDrawer = () => {
    setIsWorkOrderListDrawerOpen(false);
  }

  return (
    <Layout>
      {
        (isLoading || !workType || !workOrder) ? <></> : <>
          <Drawer
            open={isWorkOrderListDrawerOpen}
            onClose={closeWorkOrderListDrawer}
            PaperProps={{
              sx: {
                width: '50%',
                padding: '2%'
              }
            }}
          >
            <WorkOrderList
              workTypeID={workType.id}
              propsWorkOrderID={workOrderID}
            />
          </Drawer>
          <Box height='5vh'>
            <Header
              workOrderID={workOrderID}
              propertyID={workOrder.propertyID}
              screenTypeID={workType.screenType.id}
              openWorkOrderListDrawer={openWorkOrderListDrawer}
              assignedUserID={workOrder.assignedUserID}
              rowListRenderID={rowListRenderID}
            />
          </Box>
          <Box sx={{ height: '95vh' }}>
            <Grid container spacing={1} style={{ height: '100%' }}>
              <Grid item xs={7} style={{ height: '100%' }}>
                <Paper sx={{ height: '100%', maxHeight: '100%', backgroundColor: 'transparent' }}>
                  <PageRowList
                    isReadOnly={workOrder.isReadOnly || workOrder.state === WorkOrderState.Completed}
                    workOrderID={workOrder.id}
                    screenTypeID={workType.screenType.id}
                    renewRowListRenderID={renewRowListRenderID}
                    pricingGroupsRenderID={pricingGroupsRenderID}
                  />
                </Paper>
              </Grid>
              <Grid item xs={5} style={{ height: '100%' }}>
                {
                  <Paper sx={{ height: '100%', backgroundColor: 'transparent', padding: 1 }}>
                    <PagePricingSectionList
                      workOrderID={workOrderID}
                      workTypeID={workOrder.workTypeID}
                      rowListRenderID={rowListRenderID}
                      isReadOnly={workOrder.isReadOnly || workOrder.state === WorkOrderState.Completed}
                      renewPricingGroupsRenderID={renewPricingGroupsRenderID}
                    />
                  </Paper>
                }
              </Grid>
            </Grid>
          </Box>
        </>
      }
    </Layout>
  );
}