import { React, useState, useCallback } from 'react';
import Layout from '../common/components/Layout';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import GradingIcon from '@mui/icons-material/Grading';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Grid from '@mui/material/Grid';
import WorkTypeList from './components/WorkTypeList';
import WorkOrderList from './components/WorkOrderList';
import Assignees from './components/Assignees';
import { v4 as guid } from 'uuid';

export default function WorkOrderPage() {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedWorkTypeID, setSelectedWorkTypeID] = useState();
    const [workOrderListRenderID, setWorkOrderListRenderID] = useState(guid())

    const handleChangeAssigneeSuccess = () => {
        setWorkOrderListRenderID(guid());
    }

    const handleWorkTypeSelect = useCallback((workType) => {
        setSelectedWorkTypeID(workType.id);
    }, [])

    return (
        <Layout>
            <Grid container spacing={1} style={{ height: '100%' }}>
                <Grid item xs={4}>
                    <WorkTypeList handleWorkTypeSelect={handleWorkTypeSelect} selectedWorkTypeID={selectedWorkTypeID} />
                </Grid>
                <Grid item xs={8}>
                    <Tabs
                        value={selectedTabIndex}
                        onChange={(event, newValue) => setSelectedTabIndex(newValue)}
                        variant="fullWidth"
                        style={{ height: '10vh' }}
                    >
                        <Tab icon={<GradingIcon />} label="Work Orders" />
                        <Tab icon={<ContentCopyIcon />} label="Copy" />
                        <Tab icon={<AssignmentIndIcon />} label="Assignees" />
                    </Tabs>
                    <Box style={{ height: '90vh' }}>
                        <WorkOrderList
                            workTypeID={selectedWorkTypeID}
                            hidden={selectedTabIndex !== 0}
                            workOrderListRenderID={workOrderListRenderID}
                            setWorkOrderListRenderID={setWorkOrderListRenderID}
                        />
                        <Assignees
                            workTypeID={selectedWorkTypeID}
                            hidden={selectedTabIndex !== 2}
                            workOrderListRenderID={workOrderListRenderID}
                            handleChangeAssigneeSuccess={handleChangeAssigneeSuccess}
                        />
                    </Box>
                </Grid>
            </Grid>

        </Layout>
    )
};