import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


export default function CreateProjectDialog({ createProjectIsOpen, setCreateProjectIsOpen, handleCreateProjectSuccess }) {
    const approxAPIClient = ApproxAPIClient();
    const [takeoffTemplates, setTakeoffTemplates] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTakeoffTemplateID, setSelectedTakeoffTemplateID] = useState("");

    useEffect(() => {
        setIsLoading(true);
        approxAPIClient.fetchTenantTakeoffTemplates().then((response) => {
            if (response.isSuccessful === true) {
                setTakeoffTemplates(response.data);
            } else {
                alert(response.error);
            }

        }).then(() => {
            setIsLoading(false);
        });
    }, [approxAPIClient])

    const handleCreateProjectClose = () => {
        setCreateProjectIsOpen(false);
    };

    return <Dialog
        open={createProjectIsOpen}
        onClose={handleCreateProjectClose}
        fullWidth
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                setIsLoading(true);
                approxAPIClient.createProject(formJson).then((res) => {
                    if (res.isSuccessful === true) {
                        handleCreateProjectSuccess();
                    } else {
                        alert(res.error);
                    }

                    setIsLoading(false);
                    handleCreateProjectClose();
                })
            },
        }}
    >
        <DialogTitle>Create Project</DialogTitle>
        {
            !isLoading && takeoffTemplates && takeoffTemplates.length > 0 ? <>
                <DialogContent>
                    <InputLabel id="projectName-label">Project Name</InputLabel>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="projectName"
                        name="projectName"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <InputLabel id="takeoffTemplateID-select-label">Takeoff Template</InputLabel>
                    <Select
                        id="takeoffTemplateID"
                        name="takeoffTemplateID"
                        labelId="takeoffTemplateID-select-label"
                        value={selectedTakeoffTemplateID}
                        required
                        label="Takeoff Template"
                        onChange={(event) => setSelectedTakeoffTemplateID(event.target.value)}
                        fullWidth
                    >
                        {takeoffTemplates.map(x => (
                            <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateProjectClose}>Cancel</Button>
                    <Button type="submit">Create</Button>
                </DialogActions>


            </> : !isLoading && takeoffTemplates && takeoffTemplates.length === 0 ?
                <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="center">
                    There is no takeoff template please create one!
                </Typography>

                : <Box sx={{ width: '100%', padding: '5%' }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </Box>
        }
    </Dialog>
}