import React from 'react';
import Box from '@mui/material/Box';

export default function RowResult({ areaName, areaValue, color, calculationAreaGroupName, calculationAreaGroupID }) {
  const rowResultColor =
    areaValue > 0
      ? "#9AC38F"
      : areaValue < 0
        ? "#E1847E"
        : "#eee2d0";

  return (
    <Box sx={{ minWidth: '15%' }}>
      <Box
        style={{
          backgroundColor: color,
          border: `1px solid ${color}`,
          borderBottom: 'none',
          fontSize: '8px',
          textAlign: 'center',
          color: 'white',
          height: '25%',
          lineHeight: '90%'
        }}
      >
        {areaName}
      </Box>
      <Box sx={{
        height: '50%',
        textAlign: 'center',
        lineHeight: '90%',
        fontSize: '20px',
        color: 'white',
        backgroundColor: rowResultColor,
        border: `1px solid ${color}`,
        borderBottom: 'none',
        borderTop: 'none',
      }}>
        {areaValue}
      </Box>
      <Box
        style={{
          backgroundColor: 'grey',
          border: `1px solid ${color}`,
          borderTop: 'none',
          fontSize: '8px',
          textAlign: 'center',
          color: 'white',
          height: '25%',
          lineHeight: '90%'
        }}
      >
        {calculationAreaGroupName}
      </Box>
    </Box>
  );
}
